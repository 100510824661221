import React from 'react'

import Aboutimgone from "../../Components/images/icons/counter-icon-1.png"
 import Aboutimgtwo from '../../Components/images/icons/counter-icon-2.png'
import Aboutimgthree from '../../Components/images/icons/counter-icon-3.png'
 import Aboutimgfour from '../../Components/images/icons/counter-icon-4.png'
import Counter from "./Counter";
function About_sec_Counter() {
    return (
        <div>
            <div className="about-article-3 pt-25 pb-25">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="four col-lg-3 col-md-6 col-sm-6 col-dividing">
                            <div className="counter-box">
                                <img src={Aboutimgone} alt="image" />
                                <span className="counter"> <Counter number={2000}/></span>
                                <p>Themes</p>
                            </div>
                        </div>
                        <div className="four col-lg-3 col-md-6 col-sm-6 col-dividing">
                            <div className="counter-box">
                                <img src={Aboutimgtwo} alt="image" />
                                <span className="counter"> <Counter number={10000} /></span>
                                <p>sq.ft <br /> warehouse</p>
                            </div>
                        </div>
                        <div className="four col-lg-3 col-md-6 col-sm-6 col-dividing">
                            <div className="counter-box">
                                <img src={Aboutimgthree} alt="image" />
                                <span className="counter"> <Counter number={8000} /></span>
                                <p>Satisfied <br /> Customers</p>
                            </div>
                        </div>
                        <div className="four col-lg-3 col-md-6 col-sm-6 col-dividing">
                            <div className="counter-box">
                                <img src={Aboutimgfour} alt="image" />
                                <span className="counter"> <Counter number={28} /></span>
                                <p>Years of <br /> Experience</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default About_sec_Counter
