import React from 'react'
import Gallery from './Galler/Gallery'
import {Head} from '../Components/Costants'
import { useEffect } from 'react'
import { useLocation } from "react-router-dom";
import Breadcrumb from './Breadcrumb/Breadcrumb'
function Gallerys() {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  }
  useEffect(() => {
    onTop()
  }, [routePath]);
  return (
    <div>
    <Head value='5'></Head>
      <Breadcrumb mainheading="Gallery" title_one="Gallery"/>
      <Gallery/>
  
    </div>
  )
}
export default Gallerys
