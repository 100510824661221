
import Iconone from '../Components/images/icons/address.png'

 import Icontwo from '../Components/images/icons/phone.png'
 import Iconthree from '../Components/images/icons/email.png'
 import Iconfour from '../Components/images/logo.png'
import { Link } from 'react-router-dom'
import { FaFacebookF, FaInstagram ,FaPinterest } from 'react-icons/fa';
import{AiFillYoutube } from 'react-icons/ai';
import{GrLinkedinOption} from 'react-icons/gr'
import {api,footerurl} from './Costants'
import { useState,useEffect } from 'react'
function Footer() {     
    const [userst, setUsers] = useState([])
    const details_fetch = async () =>{
      const response = await fetch( api + 'company_profile_list', {
        method: "POST", 
        mode:'cors',
        headers: {
        'Accept':'aplication/json',
          "Content-Type": "application/json"
      }});
        const data = await response.json();   
        setUsers(data.records[0])
    }
     useEffect(() => {
    details_fetch();
  
  }, []) 
    return (
            <footer className="footer">
                <div className="footer-top pt-30">
                    <div className="container">
                        <div className="footer-top-content">
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className="footer-top-inner text-center">
                                        <div className="icon-part text-center">
                                            <img src={Iconone} alt="image" />
                                        </div>
                                        <h3>ADDRESS</h3>
                                        <p><a  href="https://www.google.com/maps/place/Doctors+Colony/@11.0229133,76.9676391,751m/data=!3m1!1e3!4m14!1m7!3m6!1s0x3ba85850d15c8a1d:0xedd9d61c5b0d8960!2sDoctors+Colony!8m2!3d11.023224!4d76.9704394!16s%2Fg%2F1q6jj799w!3m5!1s0x3ba85850d15c8a1d:0xedd9d61c5b0d8960!8m2!3d11.023224!4d76.9704394!16s%2Fg%2F1q6jj799w?entry=ttu"
                                        target="_blank">{userst.address1}<br/>{userst.address2}-{userst.pincode}</a></p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="footer-top-inner text-center">
                                            <div className="icon-part text-center">
                                                <img src={Icontwo} alt="image" />
                                            </div>
                                            <h3>CALL US</h3>
                                            <a href={`tel:+91${userst.mobile}`}> <p>+91 {userst.mobile}</p></a>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="footer-top-inner text-center">
                                            <div className="icon-part text-center">
                                                <img src={Iconthree} alt="image" />
                                            </div>
                                            <h3>EMAIL US</h3>
                                            <a href={`mailto:${userst.email}`}> <p> {userst.email}</p></a>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                    { /*<!-- footer top end -->
    <!-- footer row 1 start -->*/}
                    <div className="footer-row-1 position-relative pt-150 pb-25">
                        <div className="container">
                            <div className="footer-img text-center">
                                <Link to='/'><img src={Iconfour} alt="image" width="20%" /></Link>
                            </div>
                            <div className="footer-link mt-4">
                                <ul>
                                    <li> <Link  to="/"> Home</Link> </li>
                                    <li> <Link to="/about"> About Us</Link> </li>
                                    <li> <Link to="/theme"> Themes</Link> </li>
                                    <li> <Link to="/gallery"> Gallery</Link> </li>
                                    <li> <Link to="/contact"> Contact us</Link> </li>
                                </ul>
                            </div>
                            <div className="footer-social-link mt-5">
                                <ul>
                                    <li> <a href={userst.facebook}><FaFacebookF/></a> </li>
                                   <li> <a href={userst.instagram}><FaInstagram/></a> </li>
                                  <li> <a href={userst.linked_in}><GrLinkedinOption/></a> </li>
                                      <li> <a href={userst.youtube}><AiFillYoutube/></a> </li>
                                      <li> <a href={userst.twitter}> <FaPinterest/></a> </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div className="footer-row-2 pt-10 pb-10 ">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="footer-left">
                                        <p className="m-0">Copyright &#169; 2023 <Link to='/' className="font2"> {userst.company_name}</Link>. All Rights Reserved.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                               

                                    <div className="footer-right text-lg-right text-md-right ">
                                        <p className="m-0">Designed by  <Link className='font2' to='https://banyaninfotech.com'>The Banyan Infotech</Link>.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                

            </footer>
    )
}

export default Footer
