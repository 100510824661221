import React from 'react'

import Contacttwoss from './Contacttwoss'
import { useEffect ,useState } from 'react'
import {Head} from '../Costants'
import { useLocation } from "react-router-dom";
import ReactLoading from "react-loading";

function Cotactmain() {
  const routePath = useLocation();
  const [done, setDone] = useState(undefined);
  useEffect(() => {
    setTimeout(() => {
      setDone(true);
    },3000)
  }, []);
  const onTop = () => {
    window.scrollTo(0, 0);
  }
  useEffect(() => {
    onTop()
  }, [routePath]);
  return (
    <div>
    <Head value='6'></Head>
    {!done ? (
      <ReactLoading className='hh'
        type={"bars"}
        color={"#541f19"}
        height={400}
        width={100}
      />) : (
        <>
      
      <Contacttwoss/>
      </>
      )}
    </div>
  )
}

export default Cotactmain
