import React, { useEffect, useState } from 'react';
import {api} from '../Components/Costants'
import { Helmet } from 'react-helmet';
const Head = ({...props}) => {
    const [users, setUsers] = useState([]);
    const details_fetch = async () =>{
        const response = await fetch( api + 'seo_pages', {
          method: "POST", 
          mode:'cors',
          headers: {
            'Accept':'aplication/json',
            "Content-Type": "application/json"


            
          },
          body: JSON.stringify({ meta_slug: props.value }),
        });
        const data = await response.json();
       
        setUsers(data.records[0])
    }
    useEffect(() => {
        details_fetch();
    }, []);
    return(
        <Helmet>
            <title>{users.title != undefined && users.title != '' ? users.title : 'Sri Raghavendra Decors & Events'}</title>
            {users.description != undefined && users.description != '' ? <meta name='description' content={users.description} />: null}
            {users.keywords != undefined && users.keywords != '' ? <meta name='keywords' content={users.keywords}/>: null}
        </Helmet>
    ); 
}
export default Head;