import React from 'react'

import Main_Service from './Services/Main_Service'
import { useEffect , useState } from 'react'
import {Head} from './Costants'
import ReactLoading from "react-loading";
import Breadcrumb from './Breadcrumb/Breadcrumb';


import { useLocation } from "react-router-dom";
function Services() {
  const routePath = useLocation();
  const [done, setDone] = useState(undefined);
  useEffect(() => {
    setTimeout(() => {
      setDone(true);
    },3000)
  }, []);
  const onTop = () => {
    window.scrollTo(0, 0);
  }
  useEffect(() => {
    onTop()
  }, [routePath]);
  return (
    <div>
    <Head value='4'></Head>
    {!done ? (
      <ReactLoading className='hh'
        type={"bars"}
        color={"#541f19"}
        height={400}
        width={100}
      />) : (
        <>
     <Breadcrumb mainheading="SERVICES" title_one="Services"/>
     <Main_Service/> 
     </>
      )}
    </div>
  )
}

export default Services
