import React, { Component } from 'react';
import '../../css/animate.css'
import '../../css/animate.min.css'
import '../../css/bootstrap.min.css'
import '../../css/default.css'
import '../../css/fonts.css'
import '../../css/master.css'
import '../../css/responsive.css'
import Imageone from '../images/title.png'

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '.././Owl.css';
import loadable from '@loadable/component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from "@fortawesome/free-solid-svg-icons";
const options = {
    loop: true,
    margin: 15,
    nav: true,
    dots: false,
    navText: ['<i class="fas fa-arrow-left"></i>', '<i class="fas fa-arrow-right"></i>'],
    responsiveClass: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    responsive: {
        0: {
            items: 1,
            nav: false
        },
        400: {
            items: 2,
            nav: false
        },
        700: {
            items: 3,
            nav: false
        },
        1000: {
            items: 4,
            nav: false
        },
        1200: {
            items: 5
        }
    }
};

const data = [
    {
        id: 1,
        imge: require('../../Components/images/icons/icon-1.png'),
        heading: 'Stage Decoration'
    },
    {
        id: 2,
        imge: require('../../Components/images/icons/icon-2.png'),
        heading: 'Backdrop Decoration'
    },
    {
        id: 3,
        imge: require('../../Components/images/icons/icon-3.png'),
        heading: 'Outdoor Events'
    },
    {
        id: 4,
        imge: require('../../Components/images/icons/icon-4.png'),
        heading: 'Statues'
    },
    {
        id: 5,
        imge: require('../../Components/images/icons/icon-5.png'),
        heading: 'Furnitures'
    },
    {
        id: 6,
        imge: require('../../Components/images/icons/icon-6.png'),
        heading: 'Pandal & Balloon'
    },
    {
        id: 7,
        imge: require('../../Components/images/icons/icon-7.png'),
        heading: 'Lightings'
    },
    {
        id: 8,
        imge: require('../../Components/images/icons/icon-8.png'),
        heading: 'Audio System'
    },
    {
        id: 9,
        imge: require('../../Components/images/icons/icon-9.png'),
        heading: 'Bridal Flowers'
    },
    {
        id: 10,
        imge: require('../../Components/images/icons/icon-10.png'),
        heading: 'Theme Wedding'
    },
    {
        id: 11,
        imge: require('../../Components/images/icons/icon-11.png'),
        heading: 'Manavarai Decoration'
    },
    {
        id: 12,
        imge: require('../../Components/images/icons/icon-12.png'),
        heading: 'Entrance Decoration'
    },
]
const ReactOwlCarousel = loadable(() => import('react-owl-carousel'), { ssr: false });
class Home_passion extends Component {
    render() {


        return (
            <div>

                <div className="article-row-5 pt-25 pb-25">
                    <div className="container">
                        <div className="section-title text-center pb-30">
                            <h6>PASSION WITH PERFECTION</h6>
                            <h3>We create every moments a Real Memorable</h3>
                            <img src={Imageone} alt="" />
                            <span>Ready to Get Started</span>
                        </div>
                        <ReactOwlCarousel {...options} className="owl-carousel owl-theme ar5-carousel" >

                            {data.map((datas) => {
                                return (
                                    <div class="item">
                                        <a href="javascript:void(0);">
                                            <div class="ar5-inner-card">
                                                <img src={datas.imge} className="ar5-icon-image" alt="image" />
                                                <h4>{datas.heading}</h4>
                                            </div>
                                        </a>
                                    </div>
                                )
                            })}


                        </ReactOwlCarousel>
                    </div>
                </div>

            </div>
        )
    }
}

export default Home_passion
