import React from 'react'
import '../../css/animate.css'
import '../../css/animate.min.css'
import '../../css/bootstrap.min.css'
import '../../css/default.css'
import '../../css/fonts.css'
import '../../css/master.css'
import '../../css/responsive.css'

 import flowerone from  "../../Components/images/icons/flower.png";
 import Imageone from "../../Components/images/why-choose-image-1.jpg"
 import Imagetwo from "../../Components/images/why-choose-image-2.png"
 import Imagethree from '../../Components/images/ar4-bottom-left-image.png'  
 import Titleimage from '../../Components/images/title.png'
function Home_themes() {
  return (
    <div>
   
    <div className="article-row-4 pt-25 pb-25">
        <div className="container">
            <div className="row">
                <div className="col-md-6 col-lg-6 col-sm-12 left_align">
                    <div className="ar4-left-content">
                        <div className="section-title text-left pb-20">
                            <h6>Themes</h6>
                            <h3>Why to Choose us</h3>
                            <img className="small-size-title-image" src={Titleimage} alt="image"/>
                        </div>
                        <div className="ar4-right-image">
                        <img src={Imageone} className="why-choose-image-1 img-fluid" alt="image"/>
                        <img src={Imagetwo} className="why-choose-image-2 img-fluid" alt="image"/>
                    </div>
                        <p>We know what makes a perfect wedding. We know how to revive your dream wedding. There will be a team working with full commitment for you from the beginning of the event to the end. Our team has been in this field for over 28 years. We know how to bring a big smile on the face of our client's.</p>
                        <ul className="pt-20 mb-0">
                            <li>
                                <img src={flowerone} alt="image" width="20px"/>Creative Computer Designers
                            </li>
                            <li>
                                <img src={flowerone} alt="image" width="20px"/>Experienced Decorators
                            </li>
                            <li>
                                <img src={flowerone} alt="image" width="20px"/>Spacious Warehouse
                            </li>
                            <li>
                                <img src={flowerone} alt="image" width="20px"/>Perfect Planning
                            </li>
                            <li>
                                <img src={flowerone} alt="image" width="20px"/>Tailor made Themes
                            </li>
                            <li>
                                <img src={flowerone} alt="image" width="20px" />Trendy and Stylish Concepts
                            </li>
                            <li class="pb-0">
                                <img src={flowerone} alt="image" width="20px"/>Best Service throughout the event
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-6 col-lg-6 col-sm-12 right_align">
                    <div className="ar4-right-image">
                        <img src={Imageone} className="why-choose-image-1 img-fluid" alt="image"/>
                        <img src={Imagetwo} className="why-choose-image-2 img-fluid" alt="image"/>
                    </div>
                </div>
            </div>
            <div className="design-section">
                <img src={Imagethree} className="ar4-bottom-left-image" alt="image"/>
            </div>
        </div>
    </div>
  
    </div>
  )
}

export default Home_themes
