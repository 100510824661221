
import React from 'react'
import Experiancepng from '../../Components/images/icons/experience.png'
import Titlepng from '../../Components/images/title.png'
import {Link} from 'react-router-dom'
import {useState,useEffect} from 'react'
import { api, abouturl } from '../Costants'
function Home_Aboutus() {
  const [users, setUsers] = useState([])
    const details_fetch = async () =>{
      const response = await fetch( api + 'about_details', {
        method: "POST", 
        mode:'cors',
        headers: {
        'Accept':'aplication/json',
          "Content-Type": "application/json"
      }});
        const data = await response.json();
        
        setUsers( data.records[0])
    }
     useEffect(() => {
    details_fetch();
  }, [])
   return (
    <div className="article-row-3 pt-25 pb-75">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-lg-6 col-sm-12 left_align">
                    <div className="section-title text-left pb-20">
                                <h6>About SRD</h6>
                                <h3>What We Do</h3>
                                <img src={Titlepng} className='img-fluid' alt="image"></img>
                            </div>
                        <div className="ar3-left-image">
                            <img src={abouturl + users.about_image} className="about-image-1" alt='image'></img>
                            <img src={abouturl + users.about_image2} className="about-image-2" alt='image'></img> 
                        </div>
                        <div className="about-experience">
                            <img src={Experiancepng} ></img>
                            <h5>28+ <span>Years of Experience</span></h5>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-12 right_align">
                        <div className="ar3-right-content">
                            <div className="section-title text-left pb-20">
                                <h6>About SRD</h6>
                                <h3>What We Do</h3>
                                <img src={Titlepng} className='img-fluid' alt="image"></img>
                            </div>
                            
                            <p dangerouslySetInnerHTML={{__html:users.about_description}}></p>
                            <Link to='/about' className="btn style1">Read More</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
}
export default Home_Aboutus
