import React from "react";

import Double1 from '../../images/double-right-arrow.png'
import { useParams } from "react-router-dom";
import Fancy from "../../Fancy";
import { useState, useEffect } from "react";
import { api, servicedetailurl, breadcrumburl, } from "../../Costants";
import { Link } from "react-router-dom";
import "../../../css/master.css";

import { useLocation } from "react-router-dom";
const Servicedetailtwo = () => {
  const [users, setUsers] = useState([]);
  const [user3, setUsers3] = useState([]);
  const params = useParams();
  if (params.sub_metaslug != undefined && params.sub_metaslug !== "") {
    var url_name = params.sub_metaslug;
    var api_url = api + "sub_service_details";
  } else {
    var url_name = params.meta_slug;
    var api_url = api + "service_details";
  }
  const details_fetch2 = async () => {
    const response = await fetch(api_url, {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "aplication/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ meta_slug: url_name }),
    });
    const data = await response.json();
    setUsers(data.records);
  };
  useEffect(() => {
    details_fetch2();
    details_fetch3();
  }, [params]);
  //sub service list
  const details_fetch3 = async () => {
    const response = await fetch(api + "service_categories", {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "aplication/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ meta_slug: params.meta_slug }),
    });
    const data = await response.json();
    setUsers3(data.records);
  };
  useEffect(() => {
    details_fetch2();
    details_fetch3();
  }, [params]);
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  }
  useEffect(() => {
    onTop()
  }, [routePath]);


  return (
    <div>
      {users.map((st) => {
        return (
          <div className="breadcrumb-article-2 pt-80 pb-80" style={{ backgroundImage: st.subservice_backgroundimge ? `url(${breadcrumburl + st.subservice_backgroundimge})` : `url(${breadcrumburl + st.service_backgroundimge})`, backgroundSize: 'cover' }}>
            <div className="container">
              <div className="breadcrumb-section text-center">
                <h2>Services</h2>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb breadcrumb-inner">
                    <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Service</li>
                    <li className="breadcrumb-item active" aria-current="page">{st.services_title}</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        )
      }
      )}

      <div class="service-details-article-2 pt-50 pb-50">
        <div class="container">
          <div class="row">
            {users !== undefined && users.length > 0
              ? users.map((Items) => (
                <div class="col-md-8 col-lg-8 col-sm-12">
                  <div class="service-details-ar3-left-content">
                    <div class="section-title text-left pb-20">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: Items.services_description,
                        }}
                      ></span>
                      <p className="our_galeries">
                        For more details visit{" "}
                        <Link to="/gallery">
                          <b>Our Gallery </b>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <Fancy options={{ dragToClose: false }}>
                      {(Items.services_detailimages !== undefined) &
                        (Items.services_detailimages.length > 0)
                        ? Items.services_detailimages.map((images) => (
                          <div class="col-md-6 col-lg-6 col-sm-12">
                            <div class="gallery-item mb-20">
                              <a
                                class="ttm_prettyphoto ttm_image zoom-img"
                                data-fancybox="gallery-images"
                                href={
                                  servicedetailurl + images.services_image
                                }>
                                <img
                                  class="img-fluid"
                                  alt="service_images"
                                  src={
                                    servicedetailurl + images.services_image
                                  }
                                ></img>
                              </a>
                            </div>
                          </div>
                        ))
                        : ''}
                    </Fancy>
                  </div>
                </div>
              ))
              : null}
            <div class="col-md-4 col-lg-4 col-sm-12">
              <div class="service-sidebar">
                <div class="widget category">
                  <h5 class="widget-title"> Services</h5>
                  <div class="category-list">
                    {user3 != undefined && user3.length > 0
                      ? user3.map((Items) => {
                        return (
                          <div>
                            <Link
                              className="title-menu"
                              to={`/servicedetail/${Items.meta_slug}`}>
                              <img src={Double1} alt="image" />
                              <span> {Items.services_title}</span>
                              {Items.sub_service != undefined &&
                                Object.keys(Items.sub_service).length > 0
                                ? Items.sub_service.map((sub) => {
                                  return (
                                    <div>
                                      <Link
                                        className="sub-title-menu"
                                        to={`/servicedetail/${Items.meta_slug}/${sub.meta_slug}`}>
                                        <img src={Double1} alt="arrow"></img>
                                        <span> {sub.services_title}</span>
                                      </Link>
                                    </div>
                                  );
                                })
                                : null}
                            </Link>
                          </div>
                        );
                      })
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Servicedetailtwo;
