import React from 'react'

import About_About_us from './About/About_About_us'
import About_sec_Counter from './About/About_sec_Counter'
import Our_vision from './About/Our_vision'
import About_testimonial from './About/About_testimonial'
import About_Awesomw_Themes from './About/About_Awesomw_Themes'
import '../css/master.css'
import { useEffect , useState} from 'react'
import {Head} from './Costants'
import ReactLoading from "react-loading";
import Breadcrumb from './Breadcrumb/Breadcrumb'


import { useLocation } from "react-router-dom";
function About() {
  const routePath = useLocation();
  const [done, setDone] = useState(undefined);
  useEffect(() => {
    setTimeout(() => {
      setDone(true);
    },3000)
  }, []);


  const onTop = () => {
    window.scrollTo(0, 0);
  }
  useEffect(() => {
    onTop()
  }, [routePath]);
  return (
    <div>
    <Head value = '2'></Head>
    {!done ? (
      <ReactLoading className='hh'
        type={"bars"}
        color={"#541f19"}
        height={400}
        width={100}
      />) : (
        <>
      <Breadcrumb mainheading="ABOUT US" title_one="ABOUT US"/>
      <About_About_us/>
      <About_sec_Counter/> 
      <Our_vision/>
      <About_testimonial/>
      <About_Awesomw_Themes/>
</>
      )}
    </div>
  )
}

export default About
