
import { useState,useEffect } from 'react'

import Tittle from '../../Components/images/title.png';
import Arrrighttop from '../../Components/images/ar2-right-top-image.png'
import Arrleftbotom from '../../Components/images/ar2-left-bottom-image.png'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import  '../../css/fonts.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGreaterThan, faStar, faStarHalfAlt , faAngleRight} from '@fortawesome/free-solid-svg-icons'
import { api ,eventsurl} from '../Costants'
import loadable from '@loadable/component';

const options ={
    loop:true,
    margin:10,
    responsiveClass:true,
    responsive:{
        0:{
            items:1,
        },
        600:{
            items:1,
        },
        1000:{
            items:2,
        }
}
}
  function Home_events ($owl) {
    
    

    
    const [users, setUsers] = useState([])
    const details_fetch = async () =>{
    const response = await fetch(  api + 'events_list', {
        method: "POST", 
        mode:'cors',
        headers: {
        'Accept':'aplication/json',
          "Content-Type": "application/json"
      }});
      
        const data = await response.json();
        setUsers(data.records)
        
    }
    useEffect(() => {
        details_fetch();
    },[])
    const ReactOwlCarousel = loadable(() => import('react-owl-carousel'), { ssr: false });


    return (
        <div className="article-row-2 pt-50 pb-25">
            <div className='container'>
                <div class="section-title text-center pb-30 ">
                    <h6>Events</h6>
                    <h3>The Most Beautiful Events</h3>
                    <img src={Tittle} alt="" />
                </div>
                <ReactOwlCarousel  className='owl-carousel owl-theme ' id="home-events" loop margin={10} items={2} dots={false} nav={false} autoplay={true} {...options}>
                    {users !== undefined && users.length > 0 ? users.map((Items)=>(
                    <div className="item" >
                        <figure className="ar2-figure mx-4">
                            <div className="zoom-img">
                                <img src={eventsurl + Items.events_image} alt="" />
                            </div>
                            <figcaption>
                                <div className="inner-figcaption">
                                    <h5>{Items.events_title}</h5>
                                    <p>{Items.events_sub_title}</p>
                                </div>
                                <p className='ic'> <FontAwesomeIcon icon={faAngleRight} className="fawsome_cls" /> </p>    
                                </figcaption>
                        </figure>
                    </div>
                   )):null
                    }
                </ReactOwlCarousel>
                <div class="design-section">
                    <img src={Arrrighttop } className="ar2-right-top-image" alt=""/>
                    <img src={Arrleftbotom} className="ar2-left-bottom-image " alt="" />
                </div>
            </div>
        </div>
    )
}
export default Home_events
