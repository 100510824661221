import React from 'react'
import { useState, useEffect } from 'react'

import Aboutimgone from '../../Components/images/title.png'
import Aboutimgfour from '../../Components/images/icons/experience.png'
import { api, abouturl } from '../Costants'
function Abouttwo() {
    const [users, setUsers] = useState([])
    const details_fetch = async () => {
        const response = await fetch(api + 'about_details  ', {
            method: "POST",
            mode: 'cors',
            headers: {
                'Accept': 'aplication/json',
                "Content-Type": "application/json"
            }
        });
        const data = await response.json();
        setUsers(data.records[0])
    }
    useEffect(() => {
        details_fetch();
    }, [])
    return (
        <div className="about-article-2 pt-50 pb-25">
            <div className="container">
                <span dangerouslySetInnerHTML={{ __html: users.description }}></span>
                <div className="row">
                    <div className="col-md-6 col-lg-6 col-sm-12 left_align">
                        <div className="about-ar3-left-content">
                            <div className="section-title text-left pb-20">
                                <h6>{users.about_title}</h6>
                                <h3>Sri Raghavendra Decors & Events</h3>
                                <img src={Aboutimgone} alt="" />
                            </div>
                            <div className="about-ar3-right-image">
                            <img src={abouturl + users.about_image} className="about-image-1" alt="" />
                            <div className="about-image-video-section">
                                 <img src={abouturl + users.about_image2} className="about-image-2" alt="" />
                                <div className="ar7-vdo-btn">
                                    <div className="video-main">
                                        <div className="promo-video">
                                            <div className="waves-block">
                                                <div className="waves wave-1"></div>
                                                <div className="waves wave-2"></div>
                                                <div className="waves wave-3"></div>
                                            </div>
                                        </div>
                                        <a href={users.about_video} data-fancybox="video" className="video ttm_prettyphoto ttm_image" data-lity><i className="fa fa-play"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="about-experience">
                                <img src={Aboutimgfour} alt="" />
                                <h5>28+ <span>Years of Experience</span></h5>
                            </div>
                        </div>
                            <p dangerouslySetInnerHTML={{ __html: users.about_description }}></p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-12 right_align">
                        <div className="about-ar3-right-image">
                            <img src={abouturl + users.about_image} className="about-image-1" alt="" />
                            <div className="about-image-video-section">
                                 <img src={abouturl + users.about_image2} className="about-image-2" alt="" />
                                <div className="ar7-vdo-btn">
                                    <div className="video-main">
                                        <div className="promo-video">
                                            <div className="waves-block">
                                                <div className="waves wave-1"></div>
                                                <div className="waves wave-2"></div>
                                                <div className="waves wave-3"></div>
                                            </div>
                                        </div>
                                        <a href={users.about_video} data-fancybox="video" className="video ttm_prettyphoto ttm_image" data-lity><i className="fa fa-play"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="about-experience">
                                <img src={Aboutimgfour} alt="" />
                                <h5>28+ <span>Years of Experience</span></h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Abouttwo

