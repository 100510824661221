import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Fancy from "../Fancy.js";
import { api,galleryurl } from '../Costants.js'
import ReactLoading from "react-loading";
function Gallery() {
    const [done, setDone] = useState(undefined);
    useEffect(() => {
        setTimeout(() => {
            const details_fetch = async () =>{
                const response = await fetch( api + 'gallery_list  ', {
                  method: "POST", 
                  mode:'cors',
                  headers: {
                  'Accept':'aplication/json',
                    "Content-Type": "application/json"
                }});
                  const data = await response.json();
                  setUsers( data.records)
                  setDone(true)
              }
              details_fetch();
        },3000)
      }, []);

    const[index,setIndex]=useState(0)
    const [users, setUsers] = useState([])
        return (

            <div>
                {!done ? (
            <ReactLoading className='hh'
              type={"bars"}
              color={"#541f19"}
              height={400}
              width={100}
            />) : (
               <div className="gallery-article-2 pt-50 pb-50">
                        <div className="container">

                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item">

                                    <Link className={`nav-link ${index == 0 ? 'active' : null}`} onClick={() => { setIndex(0) }} id="image-tab" data-toggle="tab" to="#image" role="tab" aria-controls="image" aria-selected="true">
                                        Gallery Images
                                    </Link>
                                </li>
                                <li className="nav-item">

                                    <Link className={`nav-link ${index == 1 ? 'active' : null}`} onClick={() => { setIndex(1) }} id="video-tab" data-toggle="tab" to="#video" role="tab" aria-controls="video" aria-selected="false">
                                        Gallery videos
                                    </Link>

                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="image" role="tabpanel" aria-labelledby="image-tab" hidden={index != 0}>
                                    <Fancy options={{ dragToClose: false }}>
                                        <div className="row">
                                            {users.map((pa) => {
                                                return (<div className="col-lg-4 col-md-6 col-sm-6 mb-24 grid_dividing">
                                                    <div className="gallery-item">
                                                        <a className="ttm_prettyphoto ttm_image zoom-img" data-fancybox="gallery-images" href={galleryurl + pa.gallery_image}>
                                                            <img className="img-fluid" alt="100%x280"  src={galleryurl + pa.gallery_image}   />
                                                            
                                                        </a>
                                                    </div>
                                                </div>
                                                )

                                            })
                                            }
                                        </div>
                                    </Fancy>
                                </div>
                                <div className="tab-pane fade show active" id="video" role="tabpanel" aria-labelledby="video-tab" hidden={index != 1}>
                                    <div class="row">
                                   
                                        {
                                            users.map((av) => {
                                                return (
                                                    <div class="col-lg-4 col-md-6 col-sm-6 mb-30">
                                                        <iframe width="100%" height="250px" src={av.gallery_url} title="Decoration for an elegant and cozy, wedding stage @sriraghavendradecorsandeve6306 #sriraghav" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
               
            )}
            </div >
        )
    }


    export default Gallery
