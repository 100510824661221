import React from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import Aboutimgone from '../images/about-article-4-image.jpg'

import Aboutimgfive from "../images/title.png"

import Aboutimgeight from "../images/about-ar4-design-image.png"
import { api, abouturl } from '../Costants'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
export default function App() {
    const [active, setActive] = useState(0);
    const [activatetab, setActivatetab] = useState(0);
    const [users, setUsers] = useState([]);
    const handleClick = (id) => {
        setActivatetab(id)
    }
    
    const details_fetch = async () => {
        const response = await fetch(api + 'about_details', {
            method: "POST",
            mode: 'cors',
            headers: {
                'Accept': 'aplication/json',
                "Content-Type": "application/json"
            }
        });
        const data = await response.json();
        setUsers(data.records)
    }
    useEffect(() => {
        details_fetch();
    }, [])
    
    return (
        <div className="about-article-4 pt-25 pb-25">
            <Container>
                <div className="row">
                    <div className="col-md-6">
                        <div className="about-ar4-left-image">
                            <img src={Aboutimgone} alt="image" width="100%" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="about-ar4-right-content">
                            <Tab.Container >
                                <Row className="nav nav-tabs" id="myTab" role="tablist">
                                    {
                                        users !== undefined && users !== '' ? users.slice(1, 4).map((det, index) => {
                                            return (<Col className="nav-item">
                                                <Nav.Link id="home-tab" eventKey={index} onClick={() => { setActive(index); handleClick(index) }} className={`tab_clrs ${active == index && 'tab_clrs_active'}`}>
                                                    <img src={abouturl + det.about_image} alt="" />
                                                    {det.about_title}
                                                </Nav.Link>
                                            </Col>)
                                        }) : null
                                    }
                                </Row>
                                <Tab.Content>
                                    {
                                        users !== undefined && users !== '' ? users.slice(1, 4).map((det, index) => {
                                            return (
                                                
                                                <Tab.Pane eventKey={index} className={activatetab == index && 'active show'}>
                                                    <Row>
                                                        <div className="section-title text-left pb-20">
                                                            <h3>{det.about_title}</h3>
                                                            <img className="small-size-title-image" src={Aboutimgfive} alt="" />
                                                        </div>
                                                        <p dangerouslySetInnerHTML={{ __html: det.about_description }}></p>
                                                    </Row>
                                                </Tab.Pane>
                                            )
                                        }) : null
                                    }
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
                <div className="about-ar4-design-section">
                    <img src={Aboutimgeight} alt="image" width="100%" />
                </div>
            </Container>
        </div>
    );
}