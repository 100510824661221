import React from 'react'

import Themetwo from './Theme/Themestwo'
import { useEffect , useState } from 'react'
import {Head} from './Costants'
import ReactLoading from "react-loading";
import { useLocation } from "react-router-dom";
import Breadcrumb from '../Components/Breadcrumb/Breadcrumb'
function Themes() {
  const routePath = useLocation();
  const [done, setDone] = useState(undefined);
  useEffect(() => {
    setTimeout(() => {
      setDone(true);
    },3000)
  }, []);
  const onTop = () => {
    window.scrollTo(0, 0);
  }
  useEffect(() => {
    onTop()
  }, [routePath]);

 



  return (
    <div>     
    <Head value = '3'></Head>
    {!done ? (
      <ReactLoading className='hh'
        type={"bars"}
        color={"#541f19"}
        height={400}
        width={100}
      />) : (
        <>
  <Breadcrumb mainheading="THEMES" title_one="Themes"/>
  <Themetwo/>
  </>
      )}
    </div>
  )
}

export default Themes
