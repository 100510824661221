import React from 'react'
import Aboutimgone from '../../Components/images/title.png';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '.././Owl.css';
import { api, testimonalsurl } from '../Costants'
import { useState, useEffect } from 'react'
import loadable from '@loadable/component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";
const options = {
    loop: true,
    margin: 10,
    nav: true,
    autoplay: false,
    // autoplayTimeout: 2000,
    responsive: {
        0: {
            items: 1,
            nav: false
        },
        600: {
            items: 2,
            nav: false
        },
        1000: {
            items: 2
        }
    }
};
function About_testimonial() {
    const ReactOwlCarousel = loadable(() => import('react-owl-carousel'), { ssr: false });
    const [users, setUsers] = useState([]);
    const [maxRating, setMaxRating] = useState([1, 2, 3, 4, 5]);
    const details_fetch = async () => {
        const response = await fetch(api + 'testimonials_list', {
            method: "POST",
            mode: 'cors',
            headers: {
                'Accept': 'aplication/json',
                "Content-Type": "application/json"
            }
        });
        const data = await response.json();
        setUsers(data.records)
    }
    const getReviewCount = (data) => {
        let itemratingdatas = [];
        maxRating.map((item) => {
            itemratingdatas.push(item <= data
                ? <FontAwesomeIcon icon={faStar} style={{ color: '#ffbd17', fontSize: '16px' }} />
                : item >= data && item < data + 1
                    ? <FontAwesomeIcon icon={faStarHalfAlt} style={{ color: '#ffbd17', fontSize: '16px' }} />
                    : <FontAwesomeIcon icon={faStar} style={{ color: '#cdcdcd', fontSize: '16px' }} />)
        });
        return itemratingdatas;
    };
    useEffect(() => {
        details_fetch();
    }, [])
    return (
        <div>
            <div className="about-article-5 pt-25 pb-25">
                <div className="container">
                    <div className="section-title text-center pb-30">
                        <h6>Testimonials</h6>
                        <h3>Our Happy Clients</h3>
                        <img src={Aboutimgone} alt="image" />
                    </div>
                    <div>
                        <ReactOwlCarousel {...options}
                            className="owl-carousel owl-theme about-ar5-carousel "
                            loop
                            id="home-events"
                            nav={true}  
                            dots={false}  
                            margin={10} navText={['<i class="fas fa-arrow-left"></i>', '<i class="fas fa-arrow-right"></i>']} >
                            {users !== undefined && users.length > 0 ? users.map((Items) => (
                                <div className="testimonial-slider item">
                                    <div className="row testimonial-item">
                                        <div className="col-lg-2 col-md-12 img-part">
                                            <img className="d-block w-100" src={testimonalsurl + Items.testimonials_image} alt={Items.testimonials_image} />
                                        </div>
                                        <div className="col-lg-10 col-md-12 slider-content">
                                            <div className="row">
                                                <div className="col-md-6 title">
                                                    <h4>{Items.testimonials_name}</h4>
                                                    <p>{Items.testimonials_title}</p>
                                                </div>
                                                <div className="col-md-6 review">
                                                    {getReviewCount(Items.testimonials_ratings)}
                                                </div>
                                                <div className="col-md-12 description">
                                                    <p className="m-0">{Items.testimonials_description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )) : null
                            }
                        </ReactOwlCarousel>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default About_testimonial
