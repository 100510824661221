import React from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../../css/fonts.css'
import Tittle from '../images/title.png'
import {api , galleryurl} from '../Costants'
import Fancy from '../Fancy.js';
import { useState , useEffect } from 'react'
import loadable from '@loadable/component';
const options = {
    loop: true,
    margin: 10,
    nav: false,
    responsive: {
        0: {
            items: 1,
            nav: false,
            autoplay: true
        },
        600: {
            items: 1,
            nav: false,
            autoplay: true
        },
        1000: {
            items: 2
        }
    }
}
function Home_gallery()  {
    const [users, setUsers] = useState([])
    const image = users.slice(1, 9)
const details_fetch = async () =>{
        const response = await fetch(  api + 'gallery_list', {
          method: "POST", 
          mode:'cors',
          headers: {
          'Accept':'aplication/json',
            "Content-Type": "application/json"
        }});
          const data = await response.json();
          setUsers( data.records)
      } 
      useEffect(() => {
      details_fetch();
      }, [])
      const ReactOwlCarousel = loadable(() => import('react-owl-carousel'), { ssr: true });
        return (
            <div className="article-row-6 pt-25 pb-25">
                <div className='container'>
                    <div className="section-title text-center pb-20">
                        <h6>Gallery</h6>
                        <h3>Check Our Recent Projects</h3>
                        <img src={Tittle} alt="image"></img>
                    </div>
                    <ReactOwlCarousel className='owl-carousel owl-theme ar6-carousel' loop margin={10} items={2} dots={true}  id="home-events"
                        nav={false} autoplay={true} {...options}>
                        <Fancy options={{ dragToClose: true }}>
                        { image.length > 0 ? image.map((Items)=>(
                            <div className="item" >
                                <figure className="ar2-figure mx-2">
                                    <div className="zoom-img">   <a data-fancybox="gallery" href={galleryurl + Items.gallery_image}>
                                        <img src={galleryurl + Items.gallery_image} alt="image"></img></a>
                                    </div>
                                </figure>
                            </div>
                            )):null}
                        </Fancy>                      
                    </ReactOwlCarousel>
                </div>
            </div>
        )    
}
export default Home_gallery