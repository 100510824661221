import React from 'react'
import { Link } from 'react-router-dom'
function Breadcrumb(props) {
  return (
    <div>
      <div className="breadcrumb-article-1 pt-80 pb-80">
        <div className="container">
          <div className="breadcrumb-section text-center">
            <h2>{props.mainheading}</h2>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-inner">
                <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">{props.title_one}</li>
                <li className={props.className_one} aria-current="page">{props.title_two}</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Breadcrumb
