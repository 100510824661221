
import React from 'react'
import { Link } from 'react-router-dom'

import '../../css/master.css'
import { useState,useEffect } from 'react'
import { api, serviceurl } from '../Costants'
function Main_Service() {
    const [users, setUsers] = useState([])
const details_fetch = async () =>{
        const response = await fetch( api + 'services_list', {
          method: "POST", 
          mode:'cors',
          headers: {
          'Accept':'aplication/json',
            "Content-Type": "application/json"
        }});
          const data = await response.json();
         
          setUsers( data.records)
      }

      useEffect(() => {
        details_fetch();
      
      },[])
  return (
    <article className="article">
    <div className="service-article-2 pt-50 pb-50">
    <div className="container">
        <div className="row justify-content-center">
        {users !== undefined && users.length > 0 ? users.map((Items)=>(
            <div className="col-md-6 col-lg-6 col-sm-12 mb-25 grid-dividing">
                <figure className="ar2-figure">
                    <div className="zoom-img">
                        <img src={serviceurl + Items.services_image} alt="image"></img>
                    </div>
                    <Link to={`/servicedetail/${Items.meta_slug}`} >
                    <figcaption >
                      <h5 >{Items.services_title}</h5>
                    </figcaption>  </Link>
                </figure>
            </div>
        )):null
        }           
        </div>
    </div>
</div>
</article>
  )
}

export default Main_Service
