import React from 'react'


import { Link } from 'react-router-dom'

import {useState,useEffect} from 'react'
import { api,themeurl } from './../Costants'

function Themestwo() {
    const [users, setUsers] = useState([])
    const details_fetch = async () =>{
      const response = await fetch( api + 'themes_list  ', {
        method: "POST", 
        mode:'cors',
        headers: {
        'Accept':'aplication/json',
          "Content-Type": "application/json"
      }});
        const data = await response.json();
        
        setUsers( data.records)
    }
     useEffect(() => {
    details_fetch();
  
  }, [])
    return (
    <div>
    <div className="themes-article-2 pt-50 pb-50">
    <div className="container">
        <div className="row justify-content-center">
        {users.map((st)=>
            {
                return(
                    <div className="col-md-6 col-lg-6 col-sm-12 grid-dividing" key={st.id}>
                <figure className="ar2-figure">
                    <div className="zoom-img">
                        <img src={themeurl + st.themes_image} alt="" width="100%"/>
                    </div>
                    <figcaption>
                        <h5>{st.themes_title}</h5>
                        <Link to={`/themedetail/${st.meta_slug}`} className="btn style1">View More</Link>
                    </figcaption>
                </figure>
            </div>
                )
            })}
          </div>
    </div>
</div>
    </div>
  )
}

export default Themestwo
