import React, { useState } from 'react'
import Banner_sec from './Home/Banner_sec'
import Home_events from './Home/Home_events'
import Home_Aboutus from './Home/Home_Aboutus'
import Home_themes from './Home/Home_themes'
import Home_passion from './Home/Home_passion'
import Home_gallery from './Home/Home_gallery'
import Home_video_sec from './Home/Home_video_sec'
import Home_testimonial from './Home/Home_testimonial'
import { useEffect } from 'react'
import { useLocation } from "react-router-dom";
import {Head} from './Costants';
import ReactLoading from "react-loading";
const Home =() => {
    const routePath = useLocation();
    const [done, setDone] = useState(undefined);

    useEffect(() => {
      setTimeout(() => {
        setDone(true);
      },3000)
    }, []);
  
  const onTop = () => {
    window.scrollTo(0, 0);
  }
  useEffect(() => {
    onTop()
  }, [routePath]);
  return (
    <div>
          <Head value='1'></Head>
    {!done ? (
      <ReactLoading className='hh'
        type={"bars"}
        color={"#541f19"}
        height={400}
        width={100}
      />) : (
    <div>
      <Head value='1'></Head>
      <Banner_sec/>
      <Home_events/>
      <Home_Aboutus/>
      <Home_themes/>
      <Home_passion/>
      <Home_gallery/>
      <Home_video_sec/>
      <Home_testimonial/>
    </div>
      )}
      </div>
  )
}
export default Home
