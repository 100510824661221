import React from 'react'

import Fancy from "../Fancy.js";
import { Link } from 'react-router-dom'
import { api, themedetailsurl } from './../Costants'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import '../Costants'
import { useLocation } from "react-router-dom";
import Tittle from '../images/title.png'
import ReactLoading from "react-loading";
import Breadcrumb from '../Breadcrumb/Breadcrumb'
const Themed = () => {
    const [users, setUsers] = useState([])
    const [done,setDone] = useState(undefined)

    const params = useParams()
    const routePath = useLocation();
    const onTop = () => {
        window.scrollTo(0, 0);
    }
    useEffect(() => {
 onTop()
    }, [routePath]);
    useEffect(() => {
        setTimeout(() => {
    const details_fetch = async () => {
        const response = await fetch(api + 'themes_details', {
            method: "POST",
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                "Content-Type": "application/json"
            },
            body: JSON.stringify({meta_slug: params.meta_slug })
        });
        const data = await response.json();
        setUsers(data.records[0])
        setDone(true)

    }
    details_fetch();
        },3000)
      }, []);
   const dat=users.themes_date
    let month=new Date(dat).toLocaleString('default',{month:'long'})
    let year=new Date(dat).getFullYear();
    let day=new Date(dat).getDate();
    console.log(day,month,year)

    const[userst, setUserst] = useState([])
    console.log(userst);
    const details_fetch1 = async () =>{
      const response = await fetch( api + 'company_profile_list', {
        method: "POST", 
        mode:'cors',
        headers: {
        'Accept':'aplication/json',
          "Content-Type": "application/json"
      }});
        const data = await response.json();
        
        setUserst(data.records[0])
    }
  useEffect(() => {
    details_fetch1();
  }, [])
    return (
        <div>
             {!done ? (
            <ReactLoading className='hh'
              type={"bars"}
              color={"#541f19"}
              height={400}
              width={100}
            />) : (
            <article class="article">
               <Breadcrumb mainheading="THEMES" title_one="THEMES" title_two={users.themes_title} className_one="breadcrumb-article-1_sub_title"/>
                <div class="themes-details-article-2 pt-50 pb-26">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-md-12">
                                <figure class="ar2-figure">
                                
                        
                                    <img src={themedetailsurl + users.themes_image} alt="" width="100%" className='themedetailssecondimg'/>
                                    <figcaption>
                                    {day} <span>{month}  {year}</span>
                                       
                                    </figcaption>
                                </figure>
                            </div>
                        </div>
                        <div class="share-icon-section">

                            <div>
                             <a href={userst.facebook} class="facebook-icon-share"><i class="fab fa-facebook-f"></i><span> Facebook</span></a>
                            </div>
                            
                            <div>
                                <a href={userst.twitter} class="pinterest-icon-share"><i class="fab fa-pinterest"></i><span> Pinterest</span></a>
                            </div>
                              <div>
                                <a href={userst.instagram} class="Insta-icon-share"><i class="fab fa-instagram"></i><span>Instagram</span></a>
                            </div>
                            <div>
                                <a href={userst.linked_in} class="linkedin-icon-share"><i class="fab fa-linkedin"></i><span>Linkedin</span></a>
                            </div>
                            <div>
                                <a href={userst.youtube} class="youtube-icon-share"><i class="fab fa-youtube"></i><span>youtube</span></a>
                            </div>
                        </div>
                        
                            <div class="pt-50">
                              
                         <h3>{users.themes_title}</h3>
                          <img src={Tittle} alt=""  className='er pb-3'/>
                                <p dangerouslySetInnerHTML={{ __html: users.themes_description }}></p>
                            </div>
                            <div class="row pt-30">
                                <Fancy options={{ dragToClose: false }}>
                                   {users.themes_detailimages !== undefined && users.themes_detailimages !== '' ? users.themes_detailimages.map((det) => {
                                        return (<div class="col-md-6 mb-24">
                                            <div class="theme-details-gallery">
                                                <a class="ttm_prettyphoto ttm_image zoom-img" data-fancybox="gallery-images" href={themedetailsurl + det.theme_image}>
                                                    <img class="img-fluid" alt="100%x280" src={themedetailsurl + det.theme_image} />
                                                </a>
                                            </div>
                                        </div>)
                                    }) : null
                                    }
                                </Fancy>
                            </div>
                        
                    </div>
                </div>
            </article>
            )}
        </div>
    )
}
export default Themed
