import React, { useEffect, useState } from 'react';
import './App.css';
import './css/animate.css'
import './css/animate.min.css'
import './css/bootstrap.min.css'
import './css/default.css'
import './css/fancybox.css'
import './css/fonts.css'
import './css/master.css'
import './css/responsive.css'
import Headerss from './Components/Headerss';
import Footer from './Components/Footer'
import About from './Components/About';
import Home from './Components/Home'
import Themes from './Components/Themes';
import Themedetails from './Components/Theme/Themed';
import Services from './Components/Services';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Service_detail from './Components/Services/Servicedetail/Service_detail';
import Gallerys from './Components/Gallerys';
import Cotactmain from './Components/Contactus/Cotactmain';
import Error from './Components/Error';

function App() {
  return (
    <div>
      <BrowserRouter basename='/'>
        <Routes>
          <Route path='/' element={<div> <Headerss /><Home /> <Footer /></div>} />
          <Route path='/about' element={<div> <Headerss /><About />  <Footer /></div>} />
          <Route path='/theme' element={<div> <Headerss /><Themes /> <Footer /> </div>} />
          <Route path='/themedetail/:meta_slug' element={<div> <Headerss /><Themedetails /> <Footer /> </div>} />
          <Route path='/service' element={<div> <Headerss /><Services /> <Footer /></div>} />
          <Route path='/servicedetail/:meta_slug' element={<div> <Headerss /><Service_detail /> <Footer /></div>} />
          <Route path='/servicedetail/:meta_slug/:sub_metaslug' element={<div> <Headerss /><Service_detail /> <Footer /></div>} />
          <Route path='/gallery' element={<div><Headerss /><Gallerys /> <Footer /></div>} />
          <Route path='/contact' element={<div> <Headerss /><Cotactmain /> <Footer /></div>} />
          <Route path='*' element={<Error />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}
export default App;
