import React from 'react'
import Image1 from '../Components/images/fournotfour.png'
import '../css/master.css'
function Error() {
  return (
    <div className='row'>
   
    <div className='col-lg-12 col-sm-12 col-md-12 d-flex justify-content-center align-items-center '>
    
   <img src={Image1} 
   className='img-fluid imges'/></div>
   <div className='col-lg-12 col-sm-12 col-md-12 d-flex justify-content-center align-items-center'>
    
   <h1 className='page'>404 Page Not found</h1></div>
    </div>
  )
}

export default Error;

