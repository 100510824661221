import React, { useState, useEffect } from "react";

import Locationimg from '../../Components/images/contact-icon-1.png'
import Emailimg from "../../Components/images/contact-icon-2.png";
import Telephoneimg from "../../Components/images/contact-icon-3.png";
import Rceptionimg from "../../Components/images/contact-icon-4.png";
import Title from "../../Components/images/title.png";
import ReCAPTCHA from "react-google-recaptcha";
import { api } from "../Costants";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
function Contacttwoss() {
  const [userst, setUsers] = useState([]);
  const details_fetch = async () => {
    const response = await fetch(api + "company_profile_list", {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "aplication/json",
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();

    setUsers(data.records[0]);
  };
  useEffect(() => {
    details_fetch();
  }, []);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  let error = /^[a-zA-Z]{2,30}$/.test(name)
  let numbererror = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/.test(mobile)
  let emailrerror = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
  const handleFormSubmit = (ev) => {
    ev.preventDefault();
    const dataTosubmit = {
      name,
      email,
      mobile,
      subject,
      message,
    };
    fetch(api + 'contact', {
      method: "POST",
      headers: {
        Accept: "aplication/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(dataTosubmit),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200 || error || numbererror || emailrerror) {
          setName("")
          setMobile("")
          setEmail("")
          setSubject("")
          setMessage("")
          alert("Contact Message Send Successfully.")
        }
        else {
          alert("Some error occured");
        }
      })
  };
  const handleClick = () => {
    if (name === '') {
      document.getElementById("nameerror").innerHTML = "Name is Required";
    }
    else if (!error) {
      document.getElementById("nameerror").innerHTML = "Enter a valid name";
    }
    else {
      document.getElementById("nameerror").innerHTML = "";
    }
    //number valid
    if (mobile === '') {
      document.getElementById("numbererror").innerHTML = "Valid Mobile No is Required";
    }
    else if (!numbererror) {
      document.getElementById("numbererror").innerHTML = "Enter a Valid Mobile No";
    }
    else {
      document.getElementById("numbererror").innerHTML = "";
    }
    //email valid
    if (email === '') {
      document.getElementById("emailrerror").innerHTML = "Email Address is Required";
    }
    else if (!emailrerror) {
      document.getElementById("emailrerror").innerHTML = "Enter a Valid mail Address";
    }
    else {
      document.getElementById("emailrerror").innerHTML = "";
    }
    //subject valid
    if (subject === '') {
      document.getElementById("subjecterror").innerHTML = "Subject is Required";
    }
    else {
      document.getElementById("subjecterror").innerHTML = "";
    }
    //meesage valid
    if (message === '') {
      document.getElementById("messageerror").innerHTML = "Message is Required";
    }
    else {
      document.getElementById("messageerror").innerHTML = "";
    }
  }
  function handleKeyPress(e) {
    var key = e.key;
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      e.preventDefault();
    }
    else {
      console.log("You pressed a key: " + key);
    }
  }
  const onInputChange = e => {
    const { value } = e.target;
    console.log('Input value: ', value);
    const re = /^[A-Za-z]+$/;
    if (value === "" || re.test(value)) {
      setName(value);
    }
  }
  function onChange(value) {
    return "Captcha value:", value;
  }
  return (
    <div>
      <Breadcrumb mainheading="CONTACT US" title_one="Contact Us" />
      <div className="contact-article-2 pt-50 pb-50">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-10">
              <div className="section-title text-center pb-20 ">
                <h3>You are Welcome</h3>
                <img
                  className="small-size-title-image"
                  src={Title}
                  alt="image_tittle"
                ></img>
              </div>
              <p>
                Thanks for Showing interest in our projects, We are always happy
                to serve you, Feel free to contact us through the details we
                have provided, we will update you as soon as possible.
              </p>
            </div>
          </div>
          <div className="row pt-25">
            <div className="col-md-4 pb-25">
              <div className="contact-ar2-left">
                <h5>Quick Contact</h5>
                <p>
                  If you have any questions simply use the following details
                </p>
                <div className="contact-ar2-left-inner pt-20">
                  <figure>
                    <img src={Locationimg} alt="image"></img>
                    <figcaption>
                      <h6>Address :</h6>
                      <p>
                        <a
                          href="https://www.google.com/maps/place/Doctors+Colony/@11.0229133,76.9676391,751m/data=!3m1!1e3!4m14!1m7!3m6!1s0x3ba85850d15c8a1d:0xedd9d61c5b0d8960!2sDoctors+Colony!8m2!3d11.023224!4d76.9704394!16s%2Fg%2F1q6jj799w!3m5!1s0x3ba85850d15c8a1d:0xedd9d61c5b0d8960!8m2!3d11.023224!4d76.9704394!16s%2Fg%2F1q6jj799w?entry=ttu"
                          target="_blank"
                        >
                          {userst.address1}
                          <br />
                          {userst.address2}-{userst.pincode}
                        </a>
                      </p>
                    </figcaption>
                  </figure>
                  <figure>
                    <img src={Emailimg} alt="image"></img>
                    <figcaption>
                      <h6>Email Us :</h6>
                      <a href={`mailto:${userst.email}`}>
                        {" "}
                        <p>{userst.email}</p>
                      </a>
                    </figcaption>
                  </figure>
                  <figure>
                    <img src={Telephoneimg} alt="image"></img>
                    <figcaption>
                      <h6>Call Us :</h6>
                      <a href={`tel:+91${userst.mobile}`}>
                        {" "}
                        <p>+91 {userst.mobile}</p>
                      </a>
                    </figcaption>
                  </figure>
                  <figure>
                    <img src={Rceptionimg} alt="image"></img>
                    <figcaption>
                      <h6>Reception :</h6>
                      <p>09.00am to 06.00pm</p>
                    </figcaption>
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-md-8 pb-25">
              <div className="contact-ar2-right">
                <h5>Get in Touch</h5>
                <p className="pb-3">
                  Have some suggestions or just want to say hi? Our support team
                  are ready to help you 24/7.
                </p>
                <form method="POST" onSubmit={handleFormSubmit} autoComplete='off'>
                  <input name="robot" type="hidden" id="robot"></input>
                  <div className="row mb-3 g-3">
                    <div className="col-lg-6 col-md-6 mb-20 mt-0">
                      <input
                        type="text"

                        className="form-control"
                        placeholder="Your Name"
                        name="name"
                        id="name"
                        value={name}
                        onChange={onInputChange}
                        required data-validation="required"
                      ></input>
                      <small id='nameerror' className='error' ></small>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-20 mt-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone Number"
                        name="mobile"
                        id="mobile"
                        required data-validation="required"
                        value={mobile}
                        maxlength="10"
                        onKeyPress={(es) => handleKeyPress(es)}
                        onChange={(event) => setMobile(event.target.value)}
                      ></input>
                      <small id='numbererror' className='error'></small>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-20 mt-0">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email Address"
                        name="email"
                        id="email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        required data-validation="required"
                      ></input>
                      <small id="emailrerror" className="error"></small>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-20 mt-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Subject"
                        name="subject"
                        id="subject"
                        value={subject}
                        required data-validation="required"
                        onChange={(event) => setSubject(event.target.value)}
                      ></input>
                      <small id="subjecterror" className="error"></small>
                    </div>
                    <div className="col-lg-12 col-sm-12 mb-20 mt-0">
                      <textarea
                        className="form-control"
                        name="message"
                        type="message"
                        id="message"
                        value={message}
                        required data-validation="required"
                        placeholder="Enter your meesage"
                        onChange={(event) => setMessage(event.target.value)}
                      ></textarea>
                      <small id="messageerror" className="error"></small>
                    </div>
                    <div className="col-lg-12 col-sm-12">
                      <span className="text-input">
                        <div className="captcha mb-5">
                       
                          <ReCAPTCHA
                            sitekey={"6LfNIEUoAAAAAOuDPkDs0rjCOS0NNPk039LTWYLv"}
                            Secret Key={"6LfNIEUoAAAAALaBKpJevPuvCqmfvY2hJwuk7f9h"}
                            onChange={onChange} className="g-recaptcha"/>
                        </div>
                      </span>
                    </div>
                    <div className="col-lg-12 col-sm-12 mt-30 ">
                      <button type="submit" onClick={handleClick} className="btn style1">SEND MESSAGE</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="row pt-25">
            <div className="col-md-12">
              <div className="App">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3268.6030496204103!2d76.96786447279077!3d11.023271291348257!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba85850d15c8a1d%3A0xedd9d61c5b0d8960!2sDoctors%20Colony!5e1!3m2!1sen!2sin!4v1717134121558!5m2!1sen!2sin"
                  width="100%"
                  height="500"
                  style={{ border: "0" }}
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Contacttwoss;
