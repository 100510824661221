
import React  from 'react';
import Aboutimgone from '../images/title.png'
import Aboutimgtwo from "../images/about-gallery-1.jpg"
import Aboutimgthree from "../images/about-gallery-2.jpg"
import Aboutimgfour from "../images/about-gallery-3.jpg"
import {useState,useEffect} from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Fancy from '../Fancy.js';
import {api,themeurl} from '../Costants'
import loadable from '@loadable/component';
import { Link } from 'react-router-dom';
const options = {
    loop: true,
    margin: 10,
    nav: true,
    autoplay: true,
    autoplayTimeout: 5000,
    responsive: {
        0: {
            items: 1,
            nav: false
        },
        750: {
            items: 1,
            nav: false
        },
        1500: {
            items: 1
        },
        2000:
        {
            items:1
        }
    }
}

function About_Awesomw_Themes() {


    const ReactOwlCarousel = loadable(() => import('react-owl-carousel'), { ssr: false });
    const [users, setUsers] = useState([])
    const details_fetch = async () =>{
      const response = await fetch( api + 'themes_list  ', {
        method: "POST", 
        mode:'cors',
        headers: {
        'Accept':'aplication/json',
          "Content-Type": "application/json"
      }});
        const data = await response.json();
        
        setUsers( data.records)
    }
     useEffect(() => {
    details_fetch();
  
  }, [])
    
        return (
            <div>
                <div className="about-article-6 pt-25 pb-50">
                    <div className="container">
                        <div className="section-title text-center pb-30">
                            <h3>We Provide Awesome Themes</h3>
                            <img src={Aboutimgone} alt="image" />
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <div >
                                    <ReactOwlCarousel className="owl-carousel owl-theme about-ar6-carousel" loop margin={5} items={2} dots={true}  {...options}
                                        nav={false} autoplay={true}>
                                        <Fancy options={{ dragToClose: true }}>
                                        {users.slice(10,16).map((ts)=>
                                            {
                                                return(
                                                    <div className="item">

                                                    <div className="gallery-item mb-20">
                                                        <a className="ttm_prettyphoto ttm_image zoom-img" data-fancybox="gallery-images" href={Aboutimgtwo}>
                                                            <img className="img-fluid aboutthemepa" alt="100%x280"  src={themeurl + ts.themes_image} />
                                                        </a>
                                                    </div>
                                                    <div className='figcap'>
                                                    <figcaption>
                                                    
                                                 <h5>  <Link to={`/themedetail/${ts.meta_slug}`} >{ts.themes_title}</Link></h5>
                                                    <Link to={`/themedetail/${ts.meta_slug}`} className="btn style12">View More</Link>
                                                  
                                                </figcaption>
                                                </div>
                                                </div>
                                                )
                                            })}
                                           
                                           
                                        </Fancy>
                                    </ReactOwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


export default About_Awesomw_Themes
