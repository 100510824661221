import React from 'react'
import '../../css/animate.css'
import '../../css/animate.min.css'
import '../../css/bootstrap.min.css'
import '../../css/default.css'
import '../../css/fonts.css'
import '../../css/master.css'
import '../../css/responsive.css'

import Imageone from '../../Components/images/title-white.png'
import { useState,useEffect } from 'react'
import {api} from '../Costants'
function Home_video_sec() {

    const [users, setUsers] = useState([])
    const details_fetch = async () =>{
      const response = await fetch( api + 'about_details  ', {
        method: "POST", 
        mode:'cors',
        headers: {
        'Accept':'aplication/json',
          "Content-Type": "application/json"
      }});
        const data = await response.json();

        setUsers( data.records[0])
        
    }
    
    


   
useEffect(() => {
    details_fetch();
  
  }, [])
  
  return (
    <div>
    <div className="article-row-7 ar7-bg-overlay pt-25 pb-170">
    <div className="container">
        <div className="ar7-inner-section">
            <div className="section-title title-white text-center pb-30">
                <h6>Our Videos</h6>
                <h3>Theme Based Grand Stage Decoration</h3>
                <img src={Imageone} alt="image"/>
            </div>
            <p>Watch our Mind-Blowing Wedding & Reception Stage Decoration, at Dindigul.</p>
            <div className="ar7-vdo-btn">
                <div className="video-main">
                    <div className="promo-video">
                        <div className="waves-block">
                            <div className="waves wave-1"></div>
                            <div className="waves wave-2"></div>
                            <div className="waves wave-3"></div>
                        </div>
                    </div>
                    <a href={users.about_video} data-fancybox="video" className="video ttm_prettyphoto ttm_image" data-lity><i className="fa fa-play"></i></a>
                  
                </div>
            </div>
        </div>
    </div>
</div>
    </div>
  )
}

export default Home_video_sec
