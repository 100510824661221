import Head from "./Head";

const api = 'https://sriraghavendradecorators.com/Admin/Apidata/'
const imageurl = 'https://sriraghavendradecorators.com/Admin/uploads/'
const bannerurl = 'https://sriraghavendradecorators.com/Admin/uploads/banner/';
const testimonalsurl = 'https://sriraghavendradecorators.com/Admin/uploads/testimonials/';
const abouturl = 'https://sriraghavendradecorators.com/Admin/uploads/about/';
const eventsurl = 'https://sriraghavendradecorators.com/Admin/uploads/events/';
const themeurl = 'https://sriraghavendradecorators.com/Admin/uploads/themes/';
const galleryurl = 'https://sriraghavendradecorators.com/Admin/uploads/gallery/';
const footerurl = 'https://sriraghavendradecorators.com/Admin/uploads/';
const themedetailsurl = 'https://sriraghavendradecorators.com/Admin/uploads/themes/';
const serviceurl = 'https://sriraghavendradecorators.com/Admin/uploads/services/';
const servicedetailurl = 'https://sriraghavendradecorators.com/Admin/uploads/services/';
const breadcrumburl="https://sriraghavendradecorators.com/Admin/uploads/breadcrumbs/";
export { api, imageurl, breadcrumburl, bannerurl ,eventsurl,testimonalsurl,abouturl,themeurl,galleryurl,footerurl,themedetailsurl,serviceurl,servicedetailurl,Head}
